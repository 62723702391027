.filtered-list-container {
  display: flex;
}

.filtered-list-cards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width:100%;
  height: 100%;
  justify-content: center;
}


/* Normalize Image Response to fix creen screep */
.drinkCard{
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 24px;
}

.list-filter{
  padding: 10px 0;
  width: 100%;
}

.side-bar-container{
  padding: 10px;
}

.sidebar-button{
  width: 100%;
}

.router-link{
  text-decoration: none;
}

.drink-title{
  text-decoration: none;
  margin: auto;
  position: absolute;
  top: 50%; left: 0; right: 0;
  color: white;
  font-size: 24px;
  width: 80%;
}